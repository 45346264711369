import React from 'react';
import './privacy.css';
import { useTranslation } from 'react-i18next';

function Imprint() {
  const { t } = useTranslation();
  return (
  <div className="privacy">
    <h2>{t('imprint')}</h2>
    <h3>{t('imprint-head')}</h3>
    <p>{t('adress-name')}<br />
    {t('adress-street')}<br />
    {t('adress-city')}</p>

    <h3>{t('contact')}</h3>
    <p>Tel.: +491628271660<br />
    E-Mail: hello@anne-drotleff.de</p>
  </div> 
 
 
  );
}

export default Imprint;