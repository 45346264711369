import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Navigation from './components/Navigation/Navigation';
import Home from './site/home';
import Video from './site/video';
import Contact from './site/contact';
import About from './site/about';
import Privacy from './site/privacy';
import Imprint from './site/imprint';
import Datenschutz from './site/datenschutz';
import Events from './site/event';
import { useTranslation } from 'react-i18next';
import Portrait from './site/portrait';
import Travel from './site/travel';
import Outdoor from './site/outdoor';

function App() {
  const { t, i18n } = useTranslation();
  return (
    <Router>
        <Navigation></Navigation>
        <Switch>
          <Redirect from='/' to="/home" exact component={Home} />
          <Route path='/home' exact component={Home} />
          <Route path='/video' exact component={Video} />
          <Route path='/portrait' exact component={Portrait} />
          <Route path='/travel' exact component={Travel} />
          <Route path='/events' exact component={Events} />
          <Route path='/outdoor' exact component={Outdoor} />
          <Route path='/about' exact component={About} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/Privacy' exact component={Privacy} />
          <Route path='/Datenschutz' exact component={Datenschutz} />
          <Route path='/imprint' exact component={Imprint} />
        </Switch> 
      </Router>
  );
}

export default App;
