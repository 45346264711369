import React from 'react';
import PropTypes from 'prop-types';
import './Navigation.css';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from '@material-ui/core/Hidden';
import  {useTheme} from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import LanguageChange from '../LanguageChange/LanguageChange.';
import { useTranslation } from 'react-i18next';

function Navigation(props) {
  const { t, i18n } = useTranslation();
  const { window } = props;
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <nav className="navcontainer">
      <nav className="nav">
        <NavLink to="home" className="navelem"><span></span>{t('home')}</NavLink>
        <NavLink to="events" className="navelem"><span></span>{t('events')} </NavLink>
        <NavLink to="portrait" className="navelem"><span></span>{t('portrait')} </NavLink>
        <NavLink to="outdoor" className="navelem"><span></span>{t('landscape')} </NavLink>
        <NavLink to="travel" className="navelem"><span></span>{t('travelstorys')} </NavLink>
        <NavLink to="video" className="navelem"><span></span>{t('video')} </NavLink>
        <NavLink to="about" className="navelem"><span></span>{t('about')}</NavLink>
        <NavLink to="contact" className="navelem"><span></span>{t('contact')}</NavLink>
      </nav>
      <div className="nav secondarynav">
        <LanguageChange/>
        <a className="secondarynavelem" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/annedrotleff">Instagram</a>
        <a className="secondarynavelem" target="_blank" rel="noopener noreferrer" href="https://www.dribbble.com/annedrotleff">Dribbble</a>
        <a className="secondarynavelem" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/anne-kathrin-drotleff-016a991a8/">Linked In</a>
        <NavLink to={t('privacy')} activeClassName="secondactive" className="secondarynavelem">{t('privacy')}</NavLink>
        <NavLink to="imprint" activeClassName="secondactive" className="secondarynavelem">{t('imprint')}</NavLink>
      </div>
    </nav>
  );


  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <nav>
          <div aria-label="open drawer" onClick={handleDrawerToggle} className="menubutton">
              <span className="menu"></span>
              <span className="menu"></span>
              <span className="menu"></span>
          </div>
        
      </nav>
      <nav>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden  smUp implementation="css">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
      </nav>
    </div>
  );
}

Navigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navigation;
