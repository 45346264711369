import React from 'react';
import './Headline.css';

function Headline({head, category, text, link, linktext}){
  return(
    <div className="headlinecontainer">
        <div className="headline">
            <p>{category}</p>
            <h3>{head}</h3>
            <p>{text}</p>
            <a href={link} target="_blank" className="headbutton">{linktext}</a>
        </div>
    </div>
  );
}

Headline.propTypes = {};

Headline.defaultProps = {};

export default Headline;
