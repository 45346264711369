import React from 'react';
import './Imagelist.css';

function Imagelist({topic}){
  
  function importAll(r) {
      let images = {};
      r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
      return images;
    }

    const mountain  = importAll(require.context('../../assets/mountain', false, /\.(jpg)$/));
    const travel  = importAll(require.context('../../assets/travel', false, /\.(jpg)$/));
    const portrait  = importAll(require.context('../../assets/portrait', false, /\.(jpg)$/));

    function displayImage(topic){
      return(
        Object.keys(topic).map(key =>
          <img className="" src={topic[key]}  alt=""/>     
        ))
    }

    if (topic === 'travel') {
    return <div className="gallery">
      {
        displayImage(travel)
      }
     </div>
    }
    if (topic === 'mountain') {
      return <div className="gallery">
        {
          displayImage(mountain)
        }
       </div>
    }
    if (topic === 'portrait') {
      return <div className="gallery">
        {
          displayImage(portrait)
        }
       </div>
    }
}

Imagelist.propTypes = {};

Imagelist.defaultProps = {};

export default Imagelist;
