import React from 'react';
import './privacy.css';

function Privacy() {
  return (
  <div className="privacy">
    <h1>Privacy policy</h1>
    <h2>1. Data protection at a glance</h2>
    <h3>General information</h3> <p>The following notes provide a simple overview of what happens to your personal data when you visit this website. Personal data is all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our data protection declaration listed under this text.</p>
    <h3>Data collection on this website</h3><p>Who is responsible for data collection on this website?
        The data processing on this website is carried out by the website operator. You can find their contact details in the section "Notice on the responsible entity" in this data protection declaration.

        How do we collect your data?
        On the one hand, your data is collected when you communicate it to us. This can be z. B. be data that you enter in a contact form.

        Other data is collected automatically or with your consent by our IT systems when you visit the website. This is primarily technical data (e.g. internet browser, operating system or time of the page view). This data is collected automatically as soon as you enter this website.

        What do we use your data for?
        Part of the data is collected to ensure that the website is provided without errors. Other data can be used to analyze your user behavior.

        What rights do you have regarding your data?
        You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right, under certain circumstances, to request that the processing of your personal data be restricted. You also have the right to lodge a complaint with the competent supervisory authority.

        You can contact us at any time if you have any further questions on the subject of data protection.</p>
    <h2>2. Hosting</h2>
    <h3>External Hosting</h3> <p>This website is hosted by an external service provider (hoster). The personal data collected on this website is stored on the host's servers. This can be v. a. IP addresses, contact requests, meta and communication data, contract data, contact details, names, website access and other data generated via a website.
    The hoster is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 Para. 1 lit. b DSGVO) and in the interest of a secure, fast and efficient provision of our online offer by a professional provider (Art. 6 Para 1 lit. f GDPR).
    Our hoster will only process your data to the extent that this is necessary to fulfill its performance obligations and will follow our instructions in relation to this data.
    We use the following hoster:</p>
    <p>OVH GmbH<br />
    St. Johanner Str. 41-43<br />
    66111 Saarbr&uuml;cken<br />
    Germany<br />
    Telephone: +49 681 906730<br />
    Fax : +49 (0) 681 8761827<br />
    E-Mail : kundendienst@ovh.de</p>
    <h4>order processing</h4> <p>We have concluded an order processing contract (AVV) with the above-mentioned provider. This is a contract required by data protection law, which ensures that the personal data of our website visitors is only processed according to our instructions and in compliance with the GDPR.</p>
    <h2>3. General information and mandatory information</h2>
    <h3>Privacy</h3> <p>The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.
                    If you use this website, various personal data will be collected. Personal data is data with which you can be personally identified. This data protection declaration explains what data we collect and what we use it for. It also explains how and for what purpose this happens.
                    We would like to point out that data transmission on the Internet (e.g. when communicating by e-mail) can have security gaps. A complete protection of the data against access by third parties is not possible.</p>
    <h3>Note on the responsible entity</h3> <p>The responsible entity for data processing on this website is:</p> <p>Anne-Kathrin Drotleff<br />
    Margarethenschlucht 1<br />
    96437 Neckargerach</p>

    <p>Telephone: +491628271660<br />
    E-Mail: info@annedrotleff.de</p>
    <p>The responsible entity is the natural or legal person who, alone or together with others, decides on the purposes and means of processing personal data (e.g. names, e-mail addresses, etc.).</p>

    <h3>Storage duration</h3> <p>Unless a specific storage period has been specified in this data protection declaration, your personal data will remain with us until the purpose for data processing no longer applies. If you assert a legitimate request for deletion or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g. tax or commercial law retention periods); in the latter case, the data will be deleted once these reasons have ceased to exist.</p> 
    <h3>Note on data transfer to the USA and other third countries</h3> <p>Among other things, we use tools from companies based in the USA or other third countries that are not secure under data protection law. If these tools are active, your personal data can be transferred to these third countries and processed there. We would like to point out that in these countries no level of data protection comparable to that of the EU can be guaranteed. For example, US companies are obliged to hand over personal data to security authorities without you as the person concerned being able to take legal action against this. It can therefore not be ruled out that US authorities (e.g. secret services) will process, evaluate and permanently store your data on US servers for monitoring purposes. We have no influence on these processing activities.</p>
    <h3>Revocation of your consent to data processing</h3> <p>Many data processing operations are only possible with your express consent. You can revoke consent that you have already given at any time. The legality of the data processing that took place up until the revocation remains unaffected by the revocation.</p>
    <h3>Right to object to data collection in special cases and to direct advertising (Art. 21 GDPR)</h3> <p>IF THE DATA PROCESSING IS BASED ON ART. 6 ABS. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT AT ANY TIME TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH A PROCESSING IS BASED CAN BE FOUND IN THIS DATA PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR CONCERNED PERSONAL DATA UNLESS WE CAN PROVE COMPREHENT PROTECTIVE GROUNDS FOR THE PROCESSING THAT OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS OR THE PROCESSING IS FOR THE ENFORCEMENT, EXERCISE OR DEFENSE OF RIGHTS OBJECTION ACCORDING TO ARTICLE 21 (1) GDPR). IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF YOUR PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING; THIS ALSO APPLIES TO PROFILING TO THE EXTENT RELATED TO SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION ACCORDING TO ART. 21 (2) GDPR).</p>
    <h3>Right of appeal to the competent supervisory authority</h3> <p>In the event of violations of the GDPR, those affected have the right to lodge a complaint with a supervisory authority, in particular in the Member State of their habitual residence, their place of work or the place of the alleged violation. The right to lodge a complaint is without prejudice to any other administrative or judicial remedy.</p>
    <h3>Right to data portability</h3> <p>You have the right to have data that we process automatically on the basis of your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another controller, this will only be done insofar as it is technically feasible.</p>
    <h3>SSL or TLS encryption</h3> <p>This site uses SSL or TLS encryption for security reasons and to protect the transmission of confidential content, such as orders or requests that you send to us as the site operator. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.
      If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties. Translated with www.DeepL.com/Translator (free version)</p>
    <h3>Information, deletion and correction</h3> <p>Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, its origin and recipient and the purpose of data processing and, if necessary, a right to correction or deletion of this data. For this purpose, as well as for further questions on the subject of personal data, you can contact us at any time.</p>
    <h3>Right to restriction of processing</h3> <p>You have the right to request the restriction of the processing of your personal data. For this purpose, you can contact us at any time. The right to restriction of processing exists in the following cases:</p> 
      <ul> 
        <li>If you dispute the accuracy of your personal data stored by us, we usually need time to verify this. For the duration of the review, you have the right to request the restriction of the processing of your personal data.</li>
        <li>If the processing of your personal data happened/is happening unlawfully, you may request the restriction of data processing instead of erasure.</li>
        <li>If we no longer need your personal data, but you need it to exercise, defend or enforce legal claims, you have the right to request restriction of the processing of your personal data instead of deletion.</li> 
        <li>If you have lodged an objection pursuant to Art. 21 (1) DSGVO, a balancing of your and our interests must be carried out. As long as it has not yet been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data.</li> 
      </ul>
      <p>If you have restricted the processing of your personal data, this data may - apart from being stored - only be processed with your consent or for the assertion, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of an important public interest of the European Union or a Member State.</p>
      
    <h2>4. Instagram Privacy policy</h2>
    <h3>What is Instagram?</h3>
    <p>We have integrated Instagram functions on our website. Instagram is a social media platform of the company Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram has been a subsidiary of Facebook Inc. since 2012 and is one of the Facebook products. Embedding Instagram content on our website is called embedding. This allows us to show you content such as buttons, photos or videos from Instagram directly on our website. When you call up web pages on our website that have an Instagram function integrated, data is transmitted to Instagram, stored and processed. Instagram uses the same systems and technologies as Facebook. Your data is thus processed across all Facebook companies.
        In the following, we would like to give you a more detailed insight into why Instagram collects data, what data it is and how you can largely control the data processing. Since Instagram belongs to Facebook Inc., we obtain our information on the one hand from the Instagram policies, but on the other hand also from the Facebook data policies themselves.
        Instagram is one of the most popular social media networks in the world. Instagram combines the advantages of a blog with the advantages of audiovisual platforms such as YouTube or Vimeo. You can upload photos and short videos on "Insta" (as many of the users casually call the platform), edit them with various filters and also distribute them on other social networks. And if you don't want to be active yourself, you can just follow other interesting users.
        </p>
    <h3>Why do we use Instagram on our website?</h3>
    <p>Instagram is the social media platform that has really gone through the roof in recent years. And of course, we have also responded to this boom. We want you to feel as comfortable as possible on our website. That's why a varied preparation of our content is a matter of course for us. Through the embedded Instagram features, we can enrich our content with helpful, funny or exciting content from the Instagram world. Since Instagram is a subsidiary of Facebook, the data collected can also serve us for personalized advertising on Facebook. This way, only people who are really interested in our products or services receive our ads.
      Instagram also uses the collected data for measurement and analysis purposes. We get aggregate statistics and thus more insight about your preferences and interests. It's important to note that these reports do not identify you personally.</p>
    <h3>What data is stored by Instagram?</h3>
    <p>When you come across one of our pages that have Instagram features (such as Instagram images or plug-ins) built in, your browser automatically connects to Instagram's servers. In the process, data is sent to Instagram, stored and processed. And this is regardless of whether you have an Instagram account or not. This includes information about our website, about your computer, about purchases made, about advertisements you see and how you use our offer. Furthermore, the date and time of your interaction with Instagram is also stored. If you have an Instagram account or are logged in, Instagram stores significantly more data about you.</p>.
    <p>Facebook distinguishes between customer data and event data. We assume that this is exactly the case with Instagram. Customer data is, for example, name, address, phone number and IP address. These customer data will only be transmitted to Instagram if they have been "hashed" beforehand. Hashing means that a data record is transformed into a character string. This makes it possible to encrypt the contact data. In addition, the "event data" mentioned above is also transmitted. By "event data" Facebook - and consequently Instagram - understands data about your user behavior. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat, abgeglichen.</p>
    <p>Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden unterschiedlich viele Daten gespeichert.</p>
    <p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto haben oder <a href="http://www.instagram.com?tid=211148006" target="_blank" rel="noopener noreferrer nofollow" class="external">www.instagram.com</a> besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.</p>
    <p>In the following, we show you cookies that are set in your browser at least when you click on an Instagram function (such as button or an Insta image). In our test, we assume that you do not have an Instagram account. Of course, if you are logged into Instagram, significantly more cookies are set in your browser.</p>
    <p>These cookies were used in our test:</p>
    <p>
    <strong>Name: </strong>csrftoken<br />
    <strong>Value: </strong>“”<br />
    <strong>Usage: </strong>This cookie is most likely set for security reasons, to prevent falsification of requests. However, we could not find out more about this.<br />
    <strong>Expiration date:</strong> after one year</p><br />
    <p>
    <strong >Name: </strong>mid<br />
    <strong >Value: </strong>“”<br />
    <strong >Usage: </strong>Instagram sets this cookie to optimize its own services and offers on and off Instagram. The cookie sets a unique user ID.<br />
    <strong >Expiration date:</strong> After the end of the session</p><br />
    <p>
    <strong >Note:</strong> We cannot make any claim to completeness here. Which cookies are set in individual cases depends on the embedded functions and your use of Instagram.</p>
    <h3 >Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
    <h3>How long is the data stored and where?</h3>
    <p>Instagram shares the information it receives between Facebook companies with external partners and with people you connect with around the world. Data is processed in compliance with its own data policy. Your data is distributed on Facebook servers around the world, in part for security reasons. Most of these servers are located in the U.S.</p>
    <h3 >How can I delete my data or prevent data storage?</h3>
    <p>Thanks to the Basic Data Protection Regulation, you have the right to information, portability, correction and deletion of your data. You can manage your data in the Instagram settings. If you want to delete your data on Instagram completely, you have to delete your Instagram account permanently.</p>
    <p>And this is how deleting your Instagram account works:</p>
    <p>First, open the Instagram app. On your profile page, go down and click on "Help section". Now you will get to the company's website. On the webpage, click "Manage your account" and then click "Delete your account."</p>
    <p>When you delete your account altogether, Instagram deletes posts such as your photos and status updates. Information that other people have shared about you is not part of your account and consequently will not be deleted.</p>
    <p>As mentioned above, Instagram stores your data primarily through cookies. You can manage, disable or delete these cookies in your browser. Depending on your browser, the management always works a bit differently.</p>


    <h2>5. Data collection on this website</h2>
    <h3>Inquiry by e-mail, telephone or fax</h3><p>If you contact us by e-mail, telephone or fax, your inquiry including all resulting personal data (name, inquiry) will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent.
        The processing of this data is based on Art. 6 (1) lit. b DSGVO, if your request is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the requests sent to us (Art. 6 (1) (f) DSGVO) or on your consent (Art. 6 (1) (a) DSGVO) if this has been requested.
        The data you send to us via contact requests will remain with us until you request us to delete it, revoke your consent to store it, or the purpose for storing the data no longer applies (e.g. after your request has been processed). Mandatory legal provisions - in particular legal retention periods - remain unaffected.</p>
    <h2>6. Plugins and Tools</h2>
    <h3>YouTube</h3> <p>This website embeds videos from the website YouTube. The operator of the website is Google Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland.
          <br />When you visit one of our websites on which YouTube is embedded, a connection to the YouTube servers is established. In doing so, the YouTube server is informed which of our pages you have visited.
          <br />Furthermore, YouTube may store various cookies on your terminal device or use comparable technologies for recognition (e.g. device fingerprinting). In this way, YouTube can obtain information about visitors to this website. This information is used, among other things, to collect video statistics, improve the user experience, and prevent fraud attempts.
          <br />If you are logged into your YouTube account, you allow YouTube to associate your browsing behavior directly with your personal profile. You can prevent this by logging out of your YouTube account.
          <br />YouTube is used in the interest of an appealing presentation of our online offers. This represents a legitimate interest within the meaning of Art. 6 para. 1 lit. f DSGVO. Insofar as a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. The consent can be revoked at any time.</p> 
    <p>For more information on the handling of user data, please refer to YouTube's privacy policy at: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
    <h3>Instagram Plugin</h3> <p>Functions of the Instagram service are integrated on this website. These functions are offered by Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.
      If you are logged into your Instagram account, you can link the content of this website to your Instagram profile by clicking on the Instagram button. This allows Instagram to associate your visit to this website with your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by Instagram.
      The storage and analysis of the data is based on Art. 6 para. 1 lit. f DSGVO. The website operator has a legitimate interest in the greatest possible visibility in social media. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's terminal device (e.g. device fingerprinting) as defined by the TTDSG. The consent can be revoked at any time.
      Insofar as personal data is collected on our website with the help of the tool described here and forwarded to Facebook or Instagram, we and Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland are jointly responsible for this data processing (Art. 26 DSGVO). The joint responsibility is limited exclusively to the collection of the data and its forwarding to Facebook or Instagram. The processing by Facebook or Instagram that takes place after the forwarding is not part of the joint responsibility. The obligations incumbent on us jointly have been set out in a joint processing agreement. You can find the text of the agreement at: 
      <a href="https://www.facebook.com/legal/controller_addendum" target="_blank" rel="noopener noreferrer">https://www.facebook.com/legal/controller_addendum</a>.According to this agreement, we are responsible for providing the privacy information when using the Facebook or Instagram tool and for the privacy-secure implementation of the tool on our website. Facebook is responsible for the data security of the Facebook or Instagram products. You can assert data subject rights (e.g. requests for information) regarding the data processed by Facebook or Instagram directly with Facebook. If you assert the data subject rights with us, we are obliged to forward them to Facebook.
      The data transfer to the USA is based on the standard contractual clauses of the EU Commission. You can find details here:<a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noopener noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a>, <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noopener noreferrer">https://help.instagram.com/519522125107875</a> and <a href="https://de-de.facebook.com/help/566994660333381" target="_blank" rel="noopener noreferrer">https://de-de.facebook.com/help/566994660333381</a>.</p> <p>For more information, please see Instagram's privacy policy: <a href="https://instagram.com/about/legal/privacy/" target="_blank" rel="noopener noreferrer">https://instagram.com/about/legal/privacy/</a>.</p>
    <br />
    <br />
    <p>Source: <a href="https://www.e-recht24.de" target="_blank" rel="noopener noreferrer">https://www.e-recht24.de</a></p>
    <p>Source:  <a title="Datenschutz Generator von firmenwebseiten.at" href="https://www.firmenwebseiten.at/datenschutz-generator/" target="_blank" rel="noopener noreferrer">Privacy Policy Generator</a> by firmenwebseiten.at</p>
  </div> 
 
 
  );
}

export default Privacy;