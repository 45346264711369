import React from 'react';
import YouTube from 'react-youtube';
import Headline from '../components/Headline/Headline';
import { useTranslation } from "react-i18next";
import './detailpage.css'
import { t } from 'i18next';

function Visuals() {
  const { i18n } = useTranslation();
  
  return (
    <div className="visual">
        <div className="collabitem videoitem">
          <div>
            <Headline 
              head={t('nathalie-video-head')} 
              text={t('nathalie-video-text')} />
            <a className="button" href="https://youtu.be/Gf39NeIqmxo" target="_blank" rel="noopener noreferrer">{t('video-button')}</a>
          </div>
          <YouTube videoId='Gf39NeIqmxo' className='videocontainer'/>
        </div>

        <div className="collabitem videoitem">
          <div>
            <Headline 
              head={t('tim-head')} 
              text={t('tim-text')} />
            <a className="button" href="https://youtu.be/ky-xana5tMM" target="_blank" rel="noopener noreferrer">{t('video-button')}</a>
          </div>
          <YouTube videoId='ky-xana5tMM' className='videocontainer'/>
        </div>

        <div className="collabitem videoitem">
          <div>
            <Headline 
              head={t('blender-head')} 
              text={t('blender-text')} />
            <a className="button" href="https://youtu.be/GxLbkHqE1zc" target="_blank" rel="noopener noreferrer">{t('video-button')}</a>
          </div>
          <YouTube videoId='GxLbkHqE1zc' className='videocontainer'/>
        </div>
    </div>
  );
}

export default Visuals;