import React from 'react';
import { useTranslation } from 'react-i18next';
import Headline from '../components/Headline/Headline';
import img1 from '../assets/aboutme/2.jpg';

function About() {
  const { t, i18n } = useTranslation();
  return (
  <div className="aboutcontainer">
    <Headline 
      bg="" 
      head="creative mind and outdoor enthusiast" 
      category={t('about')} 
      text={t('about-text')} />
    <div className="aboutimage">
      <img src={img1} alt="" />
    </div>
  </div> 
  );
}

export default About;

// Neben meinem Beruf, der sehr viel vor dem Monitor stattfindet, bin für jeden Sport zu haben und bin gerne draußen unterwegs. Dabei begleitet mich immer meine Kamera. 