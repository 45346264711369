import React from 'react';
import './detailpage.css';
import { useTranslation } from 'react-i18next';
import Imagelist from '../components/Imagelist/Imagelist';
import Headline from '../components/Headline/Headline';


function Outdoor() {
  const { t, i18n } = useTranslation();
  return (
    <div className='detailpage'>
    <Headline head={t('landscape')} />
    <Imagelist topic={'mountain'} />
  </div>    
  );
}

export default Outdoor;