import React from 'react';
import { useTranslation } from 'react-i18next';
import Headline from '../components/Headline/Headline';
import './detailpage.css';

function Events() {
  const { t } = useTranslation();
    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
      }
    const baby  = importAll(require.context('../assets/events/baby', false, /\.(jpg)$/));
    const sarahmatthias  = importAll(require.context('../assets/events/sm_wedding', false, /\.(jpg)$/));
    const theresachris  = importAll(require.context('../assets/events/tc_wedding', false, /\.(jpg)$/));
    const chrissonnja  = importAll(require.context('../assets/events/sc_wedding', false, /\.(jpg)$/));
    const sister  = importAll(require.context('../assets/events/sister', false, /\.(jpg)$/));
    const theresachriscouple  = importAll(require.context('../assets/events/tc_couple', false, /\.(jpg)$/));
    const lenathommyengage  = importAll(require.context('../assets/events/lt_verlobung', false, /\.(jpg)$/));
    const lenathommy  = importAll(require.context('../assets/events/lt_wedding', false, /\.(jpg)$/));
    const nadineenrico  = importAll(require.context('../assets/events/ne_wedding', false, /\.(jpg)$/));
    const fitintime  = importAll(require.context('../assets/events/fitintime', false, /\.(jpg)$/));
    function displayImage(topic){
        return(
          Object.keys(topic).map(key =>
            <img className="" src={topic[key]}  alt=""/>     
          ))
      }

    return (
  <div className="collab">
    <div className="collabitem">
        <Headline 
          head= {t('newborn')} 
          category= {t('familie')}
          >
        </Headline>
        <div className="gallery-horizontal">{displayImage(baby)}</div>
    </div>

    <div className="collabitem">
        <Headline 
          head= {t('sarah-head')} 
          category= {t('wedding')}
          text= {t('sarah-wedding')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(sarahmatthias)}</div>
    </div>

    <div className="collabitem">
        <Headline 
          head= {t('theke-head')} 
          category= {t('wedding')}
          text= {t('theke-wedding')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(theresachris)}</div>
    </div>

    <div className="collabitem">
        <Headline 
          head= {t('chris-head')} 
          category= {t('wedding')}
          text= {t('chris-wedding')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(chrissonnja)}</div>
    </div>

    <div className="collabitem">
        <Headline 
          head= {t('lena-head')} 
          category= {t('wedding')}
          text= {t('lena-text')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(lenathommy)}</div>
    </div>

    <div className="collabitem">
        <Headline 
          bg="" 
          head= {t('sister-head')} 
          category= {t('familie')}
          text= {t('sister-text')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(sister)}</div>
    </div>

    <div className="collabitem">
        <Headline 
          head= {t('nadine-head')} 
          category= {t('wedding')}
          text= {t('nadine-text')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(nadineenrico)}</div>
    </div>

    <div className="collabitem">
        <Headline 
          head= {t('theke-head')} 
          category= {t('couple')}
          text= {t('theke-couple')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(theresachriscouple)}</div>
    </div>
  
    <div className="collabitem">
        <Headline 
          head= {t('lena-head')} 
          category= {t('engage')}
          text= {t('lena-engagement')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(lenathommyengage)}</div>
    </div>

    <div className="collabitem">
        <Headline 
          head= {t('fit-head')} 
          category= {t('brand')}
          text= {t('fit-text')}>
        </Headline>
        <div className="gallery-horizontal">{displayImage(fitintime)}</div>
    </div>
  </div>
   
  );
}

export default Events;