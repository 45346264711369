import React from 'react';
import './home.css';
import { useTranslation } from 'react-i18next';
import Homestage from '../components/Homestage/Homestage';
import ImageTeaser from '../components/ImageTeaser/ImageTeaser';
import InstagramFeed  from 'react-ig-feed';


function Home() {
  const { t } = useTranslation();
  return (
    <div className='home'>
      <Homestage/>
      <ImageTeaser />
      <div className='contact-teaser'>
        <h3>{t('contactteaser')}</h3>
        <p>{t('contactteaser-text')}</p>
      </div>
      <div className='teaser'>
        <h3>{t('uptodate')}</h3>
        <p>{t('uptodate-text')}</p>
        <InstagramFeed token="IGQWROZA1F1ZAWNidEk2T2JuMnc4bmFublNZAQmRrTU5GZAVUtQTVyT1dFeVRESmtKa1YyOVdGNUhkWHl6WWNwM2p1SlJ4SzIta05acTZARZAEVzaTRjMTM5ZAmZArVGEtZAHVLTHJieVg2OFN3RVB6VDIyUUZAqT0N6TG1Db1UZD"  counter="12"/>
      </div>
    </div>   
  );
}

export default Home;