import React from 'react';
import InstagramFeed  from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';
import './aboutcontact.css';
import { useTranslation } from 'react-i18next';
import Headline from '../components/Headline/Headline';

function Contact() {
  const { t, i18n } = useTranslation();
  return (
  <div>
    <div className='contacttop'>
      <Headline head={t('contact-head')} category={t('contact')} text={t('contact-text')} />
    </div>
 <div className="contactcontainer">
   <div className="instafeed">
       <InstagramFeed token="IGQVJWSDR1UVZAnUzNDSmRMV0VoN3F2ZA2hURi11ZAXJNYjh1WEotNHdPSkoxc09DZA2RrRFJpZAWYzRGlLUmM1OGo3T2kwNjk1cFFlTHUxREV2Tnd4ejl4bVd3b1Q4RnlIUERHSF9GUHQtWXRNWjZA0QnRGTQZDZD"  counter="9"/>
   </div>
 </div> 
 </div>
 
 
  );
}

export default Contact;