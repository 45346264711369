import React from 'react';
import PropTypes from 'prop-types';
import './ImageTeaser.css';
import  {useTheme} from '@material-ui/core/styles';
import LanguageChange from '../LanguageChange/LanguageChange.';
import { useTranslation } from 'react-i18next';
import wedding from '../../assets/events/sc_wedding/DSC_1219.jpg';
import portrait from '../../assets/portrait/title.jpg';
import travel from '../../assets/travel/IMG_7291.JPG';
import outdoor from '../../assets/mountain/m.jpg';

function ImageTeaser(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className='imageteaser-container'>
       <a href='/events' className='imageteaser'>
          <p>{t('events')}</p>
          <img src={wedding} />
      </a>

      <a href='/portrait' className='imageteaser'>
        <p>{t('portrait')}</p>
        <img src={portrait} />
      </a>

      <a href='/outdoor' className='imageteaser'>
        <p>{t('landscape')}</p>
        <img src={outdoor} />
      </a>

      <a href='/travel' className='imageteaser'>
        <p>{t('travelstorys')}</p>
        <img src={travel} />
      </a>
    </div>
      
  );
}

ImageTeaser.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ImageTeaser;
