import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './LanguageChange.css';

const LanguageChange = () =>  {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("de");

  const handleLangChange = evt => {
    const lang = evt.target.value;
    console.log(lang);
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <FormControl>
      <Select
      className='languageselect' 
      onChange={handleLangChange} 
      value={language}>
        <MenuItem value="de">Deutsch</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </Select>
    </FormControl>
  );
}

export default LanguageChange;
