import React from 'react';
import PropTypes from 'prop-types';
import './Homestage.css';
import { useTranslation } from 'react-i18next';
import photo from '../../assets/mountain/a copy.jpg';

function Homestage(props) {
  const { t } = useTranslation();

  return (
    <div className='homestage'>
      <div className='headline-container'>
          <h2>Hi.</h2>
          <h5>Portrait, Event, Family & Outdoor by Anne Drotleff.</h5>
      </div>
      <div className='image-container'>
           <img src={photo} alt="Mountain Range" />
      </div>
      <div className='intro'>
          <h4>{t('intro')}</h4>
          <h1>{t('intro-text')}</h1>
      </div>
    </div>
  );
}

Homestage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Homestage;
