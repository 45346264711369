import React from 'react';
import './detailpage.css';
import { useTranslation } from 'react-i18next';
import Imagelist from '../components/Imagelist/Imagelist';
import Headline from '../components/Headline/Headline';


function Travel() {
  const { t, i18n } = useTranslation();
  return (
    <div className='detailpage'>
      <Headline head={t('travelstorys')} text={t('travel-text')} />
      <Imagelist topic={'travel'} />
    </div>    
  );
}

export default Travel;